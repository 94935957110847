import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MoreInfoNotificationModal from './MoreInfoNotificationModal';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { desktopMediaQuery } from '../../../styleHelpers';

const text =
  "Payment processing is soon online, but required us to change the name of the site. Within a couple of weeks you will be redirected automatically to a new site with the new name. You don't have to do anything, just keep using the site as usual. If you have any questions, please contact us.";
const SHOW_MORE_INFO_BUTTON = false;
const AUTO_HIDE_NOTIFICATION_AFTER_DATE = new Date('2024-12-08');

const NotificationBar = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const [showNotification, setShowNotification] = useState(true);
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

  useEffect(() => {
    if (new Date() > AUTO_HIDE_NOTIFICATION_AFTER_DATE) {
      setShowNotification(false);
      return;
    }

    if (!loggedInUser) {
      setShowNotification(false);
      return;
    }
    const nameOfItem = 'newSiteSoon1';
    const notificationSeen = localStorage.getItem(nameOfItem);
    if (notificationSeen) {
      setShowNotification(false);
      return;
    }
    localStorage.setItem(nameOfItem, 'true');
    setShowNotification(true);
  }, [loggedInUser]);

  const handleClose = () => {
    setShowNotification(false);
  };

  const handleMoreInfo = () => {
    setShowMoreInfoModal(true);
  };

  if (!showNotification) return null;

  return (
    <>
      <NotificationBarContainer>
        <NotificationText>{text}</NotificationText>
        <ButtonContainer>
          {SHOW_MORE_INFO_BUTTON && (
            <MoreInfoButton onClick={handleMoreInfo}>More Info</MoreInfoButton>
          )}
          <CloseButton onClick={handleClose}>X</CloseButton>
        </ButtonContainer>
      </NotificationBarContainer>
      <MoreInfoNotificationModal
        show={showMoreInfoModal}
        setShow={setShowMoreInfoModal}
      />
    </>
  );
};

const NotificationBarContainer = styled.div`
  margin-left: 1%;
  width: 92%;
  background-color: #d0e7ff;
  border-radius: 4px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;

  ${desktopMediaQuery} {
    max-width: 65vw;
    margin-left: 10vw;
  }
`;

const NotificationText = styled.div`
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const MoreInfoButton = styled.button`
  color: black;
  background: none;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  color: black;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

export default NotificationBar;
